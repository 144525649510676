<template>
  <v-row justify="center">
    <v-dialog
      v-model="dialog"
      persistent
      fullscreen
      max-width="600px"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          color="primary"
          dark
          v-bind="attrs"
          v-on="on"
        >
          {{$t('booking.resources.editMap', locale)}}
        </v-btn>
      </template>
      <v-card v-if="editedItem">
        <v-card-title>
          <span class="text-h5">
            {{$t('sidebar.resources', locale)}}
          </span>
        </v-card-title>
        <v-card-text>
          <custom-canvas 
            v-if="editedItem" 
            :editedItem="editedItem" 
            :onClose="handleClose"
            :locale="locale"
            :seatMapBackground="seatMapBackground"
            :onChange="onChange"
            :onDelete="handleDelete"
            :onChangeSeatMapBackground="onChangeSeatMapBackground"
          />
        </v-card-text>
        <!--v-card-actions>
          <v-spacer/>
          <v-btn
            v-if="!isNew"
            color="red"
            @click="handleDelete"
          >
            {{ $t('common.delete', locale) }}
          </v-btn>
          <v-btn
            @click="handleClose"
          >
            {{ $t('common.close', locale) }}
          </v-btn>
          <v-btn
            color="primary"
            @click="handleSave"
          >
            {{ $t('common.save', locale) }}
          </v-btn>
        </v-card-actions-->
      </v-card>
    </v-dialog>
  </v-row>
</template>
<script>
//import Translate from '@/components/Translate'
import CustomCanvas from './Canvas'
export default {
  components: {
    CustomCanvas,
    //Translate,
  },
  props: {
    editedID: {
      type: String,
      required: true,
    },
    resourceItems: {
      type: Array,
      default: null,
    },
    onChange: {
      type: Function,
      required: true,
    },
    onChangeSeatMapBackground: {
      type: Function,
      required: true,
    },
    onDeleteSeatMap: {
      type: Function,
      required: true,
    },
    seatMapBackground: {
      type: String,
      default: null,
    },
    locale: {
      type: String,
      required: true,
    },
  },
  data: () => ({
    canvas: null,
    dialog: false,
    editedItem: null,
  }),
  computed: {
    languages () {
      return this.$store.state.app.bookingLanguages
    },
    isNew () {
      return true // TODO TMP
      //return this.editedID === 'new'
    },
  },
  watch: {
    editedID () {
      this.prepareData()
    },
    resourceItems () {
      this.prepareData()
    },
    dialog (v) {
      if (v) this.prepareData()
    },
  },
  mounted () {
    //this.prepareData()
  },
  methods: {
    prepareData () {
      this.editedItem = JSON.parse(JSON.stringify(this.resourceItems))
    },
    handleClose () {
      this.editedItem = null
      this.dialog = false
    },
    handleDelete() {
      this.onDeleteSeatMap()
      this.handleClose()
    },
  },
}
</script>

