<template>
  <v-layout wrap>
    <v-flex xs4>
    </v-flex>
    <v-flex xs4 style="height: 120px;">
      <select-image 
        :image="image"
        :onChange="handleChangeImage"
        :locale="locale"
        :imageWidth="1024"
        :imageHeight="768"
        :aspectRatio="16/9"
        :folder="workspaceID"
      />
    </v-flex>
    <v-flex xs4>
      <center>
      <v-btn
        @click="onDelete"
        style="margin-right: 20px;"
        color="red"
        dark
      >
        {{ $t('common.delete', locale) }}
      </v-btn>
      <v-btn
        @click="onClose"
        style="margin-right: 20px;"
      >
        {{ $t('common.close', locale) }}
      </v-btn>
      <v-btn
        color="primary"
        @click="handleSave"
      >
        {{ $t('common.save', locale) }}
      </v-btn>
      </center>
    </v-flex>
    <v-flex xs12>
      <center>
            <canvas 
              ref="canvas" 
              width="1024"
              height="768"
              style="border:1px solid black;background-size: cover;"
              :style="{ backgroundImage: `url(${urlBackground})` }" 
            />
      </center>
    </v-flex>
  </v-layout>
  <!--v-card id="main-seats-map">
    <v-responsive :aspect-ratio="16/9">
      <v-card-text style="padding: 0;">
        <canvas 
          ref="canvas" 
          width="1024"
          height="768"
        />
      </v-card-text>
    </v-responsive>
  </v-card-->
</template>
<script>
//import Translate from '@/components/Translate'
import { fabric } from 'fabric'
import utils from '@/services/utils'
import SelectImage from '@/components/selectImage/Index'
export default {
  components: {
    SelectImage,
  },
  props: {
    seatMapBackground: {
      type: String,
      default: null,
    },
    editedItem: {
      type: Array,
      required: true,
    },
    onClose: {
      type: Function,
      required: true,
    },
    locale: {
      type: String,
      required: true,
    },
    onChange: {
      type: Function,
      required: true,
    },
    onDelete: {
      type: Function,
      required: true,
    },
    onChangeSeatMapBackground: {
      type: Function,
      required: true,
    },
  },
  data: () => ({
    canvas: null,
    image: null,
    background: null,
    urlBackground: null,
  }),
  computed: {
    workspaceID () {
      return utils.getWorkspace('booking')
    },
  },
  watch: {
    /*canvas (v) {
      this.handleChange(v)
    },*/
    editedItem () {
      this.prepareItem()
    },
  },
  mounted () {
    this.canvas = new fabric.Canvas(this.$refs.canvas)
    //this.canvas.setBackgroundColor('#cccccc80')

    // Create a new instance of the Image class
    this.background = new Image()
    // When the image loads, set it as background image
    this.background.onload = () => {
        //var f_img = new fabric.Image(this.background)
       // this.canvas.setBackgroundImage(f_img)
        this.canvas.renderAll()
    }

    this.prepareItem()
  },
  methods: {
    prepareItem () {
      this.canvas.remove(...this.canvas.getObjects())
      if (this.seatMapBackground) {
        this.background.src = this.seatMapBackground
        this.urlBackground = this.seatMapBackground
      }
      for (const item of this.editedItem.filter(x => x.SeatMapImage && x.SeatMapImage.length > 0)) {
        new fabric.Image.fromURL(item.SeatMapImage, (oImg) => {
          // scale image down, and flip it, before adding it onto canvas
          item.cOLD = oImg.set({
            width: item.Width ? item.Width : 200,
            height: item.Height ? item.Height : 200,
            //top: item.Top ? item.Top : 5,
            //left: item.Left ? item.Left : 0,
            angle: item.Angle ? item.Angle : 0,//.155944313037946
            //scaleX: item.ScaleX ? item.ScaleX : 1,
            //scaleY: item.ScaleY ? item.ScaleY : 1,
            originX: 'center',
            originY: 'center',
          })
          .setControlsVisibility({ mtr: false })


          var text = new fabric.Text(utils.getTranslation(item.Name, this.locale), {
            fontSize: 10,
            originX: 'center',
            originY: 'center',
          })
          item.c = new fabric.Group([ oImg, text ], {
                      //width: item.Width ? item.Width : 200,
                      //height: item.Height ? item.Height : 200,
                      width: item.Width ? item.Width : 200,
                      height: item.Height ? item.Height : 200,
                      top: item.Top ? item.Top : 5,
                      left: item.Left ? item.Left : 0,
                      angle: item.Angle ? item.Angle : 0,//.155944313037946
                      scaleX: item.ScaleX ? item.ScaleX : 1,
                      scaleY: item.ScaleY ? item.ScaleY : 1,
          })
          .setControlsVisibility({ mtr: false })

          this.canvas.add(item.c)
          //this.canvas.add(oImg)
        })
        /*
        item.c = new fabric.Rect({
          fill: 'red',
          width: item.Width ? item.Width : 100,
          height: item.Height ? item.Height : 100,
          top: item.Top ? item.Top : 5,
          left: item.Left ? item.Left : 101 * x,
          angle: item.Angle ? item.Angle : 0,//.155944313037946
          scaleX: item.ScaleX ? item.ScaleX : 1,
          scaleY: item.ScaleY ? item.ScaleY : 1,
        })
        this.canvas.add(item.c)
        */
      }
    },
    handleChangeImage (v) {
      this.onChangeSeatMapBackground(v)
      this.background.src = v.base64
      this.urlBackground = v.base64
    },
    handleSave () {
      this.onChange(this.editedItem.map(item => { 
        console.log(item.c)
        return {
          id: item.ID,
          top: item.c.top,
          left: item.c.left,
          width: item.c.width,
          height: item.c.height,
          scaleX: item.c.scaleX,
          scaleY: item.c.scaleY,
          angle: item.c.angle,
        }
      }))
      this.onClose()
    },
  },
}
</script>

